import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VList,{staticClass:"pt-0 pb-8",attrs:{"dense":""}},[_c('profile-menu',{staticClass:"pa-0",model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_vm._l((_vm.menuItems),function(item,i){return _c('div',{key:i},[(item.divider && !_vm.hideMenu(item))?_c(VDivider):_vm._e(),(!_vm.hideMenu(item) && item.title)?_c(VListItem,{class:_vm.unavailableParams(item.link) ? 'grey--text' : 'primary--text',attrs:{"link":!item.group,"disabled":_vm.unavailableParams(item.link),"to":_vm.getRoute(item)}},[(!item.group)?_c(VListItemIcon,[(!!_vm.hasBadge(item.resource))?_c(VBadge,{attrs:{"color":"red","label":"","bordered":"","left":"","overlap":"","content":_vm.getMenuBadgesCounter(item.resource)}},[_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))])],1):_vm._e(),(!_vm.hasBadge(item.resource))?_c(VIcon,{attrs:{"large":""}},[_vm._v(_vm._s(item.icon))]):_vm._e()],1):_vm._e(),_c(VListItemContent,{class:("title" + (item.group ? ' text-uppercase' : ''))},[_c(VListItemTitle,{style:(item.group ? 'font-size: 9pt;' : '')},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])],1)],1):_vm._e()],1)}),_c(VDivider),(_vm.isDriver)?_c(VListItem,{on:{"click":function($event){return _vm.toggleActiveMode()}}},[_c(VListItemIcon,[(_vm.activeMode)?_c(VIcon,{attrs:{"large":"","color":"green"}},[_vm._v("mdi-toggle-switch")]):_vm._e(),(!_vm.activeMode)?_c(VIcon,{attrs:{"large":"","color":"grey"}},[_vm._v("mdi-toggle-switch-off-outline")]):_vm._e()],1),_c(VListItemContent,{staticClass:"title"},[_c(VListItemTitle,[_vm._v(" "+_vm._s(_vm.activeMode ? _vm.$t("general.available") : _vm.$t("general.unavailable"))+" ")])],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }