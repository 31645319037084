// import { mdiAccount } from '@mdi/js'

export default [
  {
    title: "app.route.stores",
    resource: "stores",
    icon: "mdi-store",
    link: "/#/stores",
    to: "stores",
  },
  {
    title: "app.route.cart",
    resource: "cart",
    icon: "mdi-cart-variant",
    link: "/#/cart/:storeId",
    to: "cart",
  },
  {
    title: "app.route.orders",
    resource: "orders",
    icon: "mdi-format-list-bulleted-type",
    link: "/#/orders",
    to: "orders",
  },
  {
    title: "app.route.favorites",
    resource: "favorites",
    icon: "mdi-heart",
    link: "/#/favorites/:storeId",
    to: "favorites",
  },
  // {
  //   title: "app.route.offers",
  //   resource: "offers",
  //   icon: "mdi-tag-multiple-outline",
  //   link: "/#/offers",
  //   to: "offers",
  // },

  // driver
  {
    title: "driver.route.activeDelivery",
    resource: "delivery.active",
    icon: "mdi-truck-fast",
    link: "/#/delivery",
    to: "delivery.active",
  },

  // admin
  {
    title: "admin.route.management",
    divider: true,
    group: true,
    groupId: "management",
  },
  {
    title: "admin.route.dashboards",
    resource: "dashboards",
    icon: "mdi-chart-bar",
    link: "/#/admin/dashboards",
    to: "dashboards",
    groupId: "management",
  },
  {
    title: "admin.route.customers",
    resource: "customers",
    icon: "mdi-account-multiple",
    link: "/#/admin/customers",
    to: "customers",
    groupId: "management",
  },
  {
    title: "admin.route.offers",
    resource: "offers.manager",
    icon: "mdi-tag-plus",
    link: "/#/admin/offers",
    to: "offers.manager",
    groupId: "management",
  },

  {
    title: "admin.route.control",
    divider: true,
    group: true,
    groupId: "admin.control",
  },
  {
    title: "admin.route.users",
    resource: "users",
    icon: "mdi-account-tie",
    link: "/#/admin/users",
    to: "users",
    groupId: "admin.control",
  },
  {
    title: "admin.route.roles",
    resource: "roles",
    icon: "mdi-shield-lock",
    link: "/#/admin/roles",
    to: "roles",
    groupId: "admin.control",
  },
  {
    title: "admin.route.notifications",
    resource: "notifications.options",
    icon: "mdi-email-send",
    link: "/#/admin/notifications",
    to: "notifications.options",
    groupId: "admin.control",
  },
  {
    title: "admin.route.regionsStores",
    resource: "regions",
    icon: "mdi-flag",
    link: "/#/admin/regions",
    to: "regions",
    groupId: "admin.control",
  },
  {
    title: "admin.route.dataTypes",
    resource: "data.types",
    icon: "mdi-database-cog-outline",
    link: "/#/admin/datatypes",
    to: "data.types",
    groupId: "admin.control",
  },

  {
    title: "app.route.business",
    divider: true,
    group: true,
    groupId: "business",
  },

  // priority for router params then object params
  {
    title: "app.route.managedStores",
    resource: "managed.stores.profiles",
    icon: "mdi-store-outline",
    link: "/#/business/stores/profiles",
    to: "managed.stores.profiles",
    groupId: "business",
  },
  {
    title: "app.route.products",
    resource: "managed.stores.products",
    icon: "mdi-barcode-scan",
    link: "/#/business/stores/products",
    to: "managed.stores.products",
    groupId: "business",
  },
  {
    title: "app.route.dispatchers",
    resource: "managed.stores.dispatchers",
    icon: "mdi-truck-delivery",
    link: "/#/business/stores/dispatchers",
    to: "managed.stores.dispatchers",
    groupId: "business",
  },

  {
    title: "app.route.settings",
    divider: true,
    group: true,
    groupId: "settings",
  },
  {
    title: "app.route.accountSettings",
    resource: "settings",
    icon: "mdi-cog",
    link: "/#/settings",
    to: "settings",
    groupId: "settings",
  },
];
