<template>
  <v-app-bar app color="secondary" flat height="56" style="z-index: 10">
    <v-app-bar-nav-icon
      @click.stop="$emit('toggle', !drawer)"
    ></v-app-bar-nav-icon>

    <v-divider vertical />

    <!-- Search Box -->
    <v-btn
      icon
      large
      v-if="getStoreIdParam > 0 ? $allowed('store') : $allowed('stores')"
      @click="goToSearch"
    >
      <v-icon :large="isMd">mdi-magnify</v-icon>
    </v-btn>

    <v-spacer></v-spacer>

    <span
      v-if="isCustomer"
      style="line-height: 14px; font-weight: 600; font-size: 11pt"
    >
      {{ getAddressString }}
    </span>
    <v-btn
      icon
      large
      v-if="isCustomer"
      @click="
        $router.push({
          name: 'addresses',
          params: { back: { name: homePage } },
        })
      "
    >
      <v-icon :large="isMd">mdi-home-map-marker</v-icon>
    </v-btn>

    <v-divider v-if="isCustomer && !isMd" vertical />

    <!-- Application Menu -->
    <v-btn icon large v-if="!isMd">
      <v-icon @click="fullscreen()">mdi-fullscreen</v-icon>
    </v-btn>

    <v-badge
      v-if="showCart"
      :content="getCartItemsQuantity"
      right
      top
      offset-y="22"
      offset-x="22"
      color="red"
      style="font-weight: 600"
      overlap
      small
    >
      <v-chip
        label
        :to="getCartRoute"
        color="white"
        class="body-2 px-0"
        large
        style="max-height: 46px"
      >
        <v-btn icon depressed tile min-width="65">
          <v-icon style="position: absolute; top: -21px">
            mdi-cart-variant
          </v-icon>
          <div
            class="text--primary"
            style="
              position: absolute;
              top: -2px;
              font-weight: 600;
              letter-spacing: normal;
            "
          >
            <sup>
              {{ getCartCurrency || "" }}
            </sup>
            <span>
              {{ getCartTotalPrice.toLocaleString() }}
            </span>
          </div>
        </v-btn>
      </v-chip>
    </v-badge>

    <v-btn
      v-if="!getCurrentUser.uuid"
      link
      to="/auth/register"
      depressed
      color="secondary"
      class="primary--text"
    >
      Register
    </v-btn>

    <v-btn
      v-if="!getCurrentUser.uuid"
      link
      to="/auth/login"
      depressed
      color="secondary"
      class="ml-2 primary--text"
    >
      Login
    </v-btn>

    <!-- ${this.$vuetify.theme.themes.light.secondary}; -->
    <v-divider
      :style="`border-width: 1px;
        background: ${getBorderColor};
        position: absolute;
        bottom: 0px;
        left: 0;
        right: 0;`"
    />
  </v-app-bar>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppBar",
  model: {
    prop: "drawer",
    event: "toggle",
  },
  props: {
    drawer: Boolean,
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("cart", [
      "getCartItemsQuantity",
      "getCartTotalPrice",
      "getCartCurrency",
      "getCartStoreId",
    ]),
    ...mapGetters("stores", ["getOpenStore"]),

    getCartRoute() {
      return "/cart/" + this.getCartStoreId;
    },
    isCustomer() {
      const { userType } = this.getCurrentUser
      return this.$hasRole("CUSTOMER") && userType === "CUSTOMER";
    },
    getAddressString() {
      const { activeAddress: a } = this.getCurrentUser;
      if (a) {
        return `${a.title}/ ${a.buildingNumber} ${a.street}${
          a.area ? ", " + a.area : ""
        }`;
      }

      return "";
    },
    getAvatar() {
      return `${this.getApiBaseUrl}/files/avatar/${this.getCurrentUser.uuid}`;
    },
    showCart() {
      return this.$allowed("cart") && this.getCartTotalPrice > 0;
    },
    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),

    async tryLogout() {
      await this.logout();
      this.$router.push({ name: "login" });
    },
    goToSearch() {
      if (this.getStoreIdParam > 0) {
        this.$router.push({
          name: "products.search",
          params: { storeId: this.getStoreIdParam },
        });
      } else {
        this.$router.push({ name: "stores.search" });
      }
    },
    fullscreen() {
      this.$fullscreen.toggle();
    },
  },
};
</script>
