<template>
  <v-list dense class="pt-0 pb-8">
    <profile-menu v-model="user" class="pa-0" />

    <div v-for="(item, i) in menuItems" :key="i">
      <v-divider v-if="item.divider && !hideMenu(item)" />
      <v-list-item
        :link="!item.group"
        :disabled="unavailableParams(item.link)"
        :to="getRoute(item)"
        :class="unavailableParams(item.link) ? 'grey--text' : 'primary--text'"
        v-if="!hideMenu(item) && item.title"
      >
        <v-list-item-icon v-if="!item.group">
          <v-badge
            v-if="!!hasBadge(item.resource)"
            color="red"
            label
            bordered
            left
            overlap
            :content="getMenuBadgesCounter(item.resource)"
          >
            <v-icon large>{{ item.icon }}</v-icon>
          </v-badge>
          <v-icon large v-if="!hasBadge(item.resource)">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content
          :class="`title${item.group ? ' text-uppercase' : ''}`"
        >
          <v-list-item-title :style="item.group ? 'font-size: 9pt;' : ''">
            {{ $t(item.title) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-divider />
    <v-list-item
        @click="toggleActiveMode()"
        v-if="isDriver"
      >
        <v-list-item-icon>
          <v-icon large color="green" v-if="activeMode">mdi-toggle-switch</v-icon>
          <v-icon large color="grey" v-if="!activeMode">mdi-toggle-switch-off-outline</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="title">
          <v-list-item-title>
            {{ activeMode ? $t("general.available") : $t("general.unavailable") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import navigations from "./navigations";
import ProfileMenu from "@/common/components/ProfileMenu";

export default {
  name: "NavigationMenu",
  components: { ProfileMenu },
  data() {
    return {
      menuItems: [],
      user: this.$getUserInfo(),
    };
  },
  mounted() {
    this.menuItems = navigations.map((m) => {
      return m.resource
        ? { ...m, can: this.$allowed(m.resource) }
        : { ...m, can: true };
    });
  },
  computed: {
    ...mapGetters("auth", ["getCurrentUser"]),
    ...mapGetters("ui", ["getMenuBadges", "getMenuBadgesCounter"]),

    isDriver() {
      const { userType } = this.getCurrentUser
      return this.$hasRole("DRIVER") && userType === "DRIVER";
    },
    activeMode() {
      const { statusName } = this.getCurrentUser;
      return statusName === "ACTIVE";
    },
  },
  methods: {
    ...mapActions("users", ["setDriverStatus"]),

    hasBadge(resource) {
      const badge = this.getMenuBadges(resource);
      return this.notEmptyArray(badge);
    },
    getRoute(item) {
      const badges = this.getMenuBadges(item.resource);
      if (badges && item.to) {
        return {
          name: item.to,
          query: { highlight: badges.join(",") },
        };
      } else if (item.to) {
        return { name: item.to, params: this.getParams(item.link) };
      }
      return null;
    },
    getParams(link) {
      if(!link) {
        return null;
      }

      const vals = [];
      const linkParamsMatches = link.match(/:(\w+)\/?/g);
      const linkParams = linkParamsMatches
        ? linkParamsMatches.map((m) => m.replace(/:|\//g, ""))
        : null;
      for (var pi in linkParams) {
        const paramName = linkParams[pi];
        const lp = this.$route.params[paramName];
        vals.push(!!lp);
        link = link.replace(new RegExp(":" + paramName), lp);
      }
      return vals.every(v => v) ? link : [];
    },
    unavailableParams(link) {
      const params = this.getParams(link);
      return  params && params instanceof Array && params.length === 0;
    },
    hideMenu(item) {
      const hide = !item.can;
      if (item.group) {
        const groupItems = this.menuItems.filter(
          (i) => !i.group && i.groupId && i.groupId === item.groupId && i.can
        );
        return groupItems.length === 0;
      }
      return hide;
    },
    toggleActiveMode() {
      const { uuid, statusName } = this.getCurrentUser;
      this.setDriverStatus({uuid, status: statusName !== "ACTIVE" ? "ACTIVE" : "INACTIVE"});
    }
  },
};
</script>
