<template>
  <span></span>
</template>

<script>
import { mapActions } from "vuex";
import Push from "push.js";
import mixin from "@/views/orders/orders.mixin";

export default {
  name: "NativeNotification",
  mixins: [mixin],
  data() {
    return {
      gotoText: this.$t("general.goto"),
    };
  },
  mounted() {
    this.init(this);
  },
  computed: {
    ...mapActions("orders", ["getActiveOrders"]),
  },
  methods: {
    ...mapActions("ui", ["addToMenuBadge", "showFeedback"]),
    ...mapActions("comments", ["pushAlert"]),
    ...mapActions("orders", [
      "loadActiveOrders",
      "loadMyOrders",
      "loadDriverActiveOrders",
    ]),
    ...mapActions("users", [
      "publishDriverLocation",
      "unpublishDriverLocation",
      "loadUsers",
      "setDriverStatus",
    ]),
    ...mapActions("auth", ["logout"]),

    init(self) {
      const { roles, regionId, storeId, uuid } = self.$getUserInfo();

      try {
        Push.Permission.request();
      } catch {
        console.log("No notification support");
      }

      // this.$socketConnect();

      // this.$wsConnect({ roles, regionId, storeId, uuid }, (frame) => {
      //   const { body } = frame;
      //   const data = JSON.parse(body);

      //   self.addToMenuBadge({ resource: data.resource, type: data.actionType });

      //   const goTo = () => {
      //     self.$router.push({
      //       name: data.resource,
      //       query: { highlight: data.resourceId },
      //     });
      //   };

      //   const text =
      //     data.comment && data.comment.length > 250
      //       ? data.comment.substr(0, 250)
      //       : data.comment;

      //   const userType = self.$hasRole("DRIVER")
      //     ? "driver"
      //     : self.$hasRole("ORDER-HANDLER")
      //     ? "handler"
      //     : self.$hasRole("SUPER-ADMIN") || self.$hasRole("ADMIN")
      //     ? "admin"
      //     : "customer";

      //   if (data.resourceType === "orders") {
      //     if (userType === "admin" || userType === "handler") {
      //       self.loadActiveOrders();
      //       if(data.status === "WAITING") {
      //         const newOrderAudio = new Audio("aud/new_order.wav");
      //         newOrderAudio.play();
      //       }
      //     } else if (userType === "driver") {
      //       self.loadDriverActiveOrders();
      //       if (data.status === "DISPATCHED") {
      //         self.publishDriverLocation();
      //       } else if (data.status === "DELIVERED") {
      //         if (
      //           !self
      //             .getActiveOrders()
      //             .some(
      //               (s) =>
      //                 s.activeTrackStatus === "DISPATCHED" ||
      //                 s.activeTrackStatus === "DELIVERY" ||
      //                 s.activeTrackStatus === "NEAR"
      //             )
      //         ) {
      //           self.unpublishDriverLocation();
      //           const { uuid } = self.$getUserInfo();
      //           self.setDriverStatus({ uuid, status: "RETURNING" });
      //         }
      //       }
      //     } else if (userType === "customer") {
      //       self.loadMyOrders();
      //       if(data.status === "NEAR") {
      //         const newOrderAudio = new Audio("aud/near.wav");
      //         newOrderAudio.play();
      //       }
      //     }
      //   } else if (data.resourceType === "users" && userType === "admin") {
      //     self.loadUsers();
      //   } else if (data.resourceType === "action" && data.status === "logout") {
      //     self.logout();
      //   }

      //   const messageKey = `order.update.${userType}.${data.body}`;
      //   if (data.notify && data.resource) {
      //     self.showFeedback({
      //       show: true,
      //       title: data.title,
      //       text: self.$t(messageKey),
      //       timeout: 7000,
      //       color: `${self.getStatusTypeColor(data.status)} lighten-2`,
      //       light: true,

      //       // light: self.isDarkStatusTheme(data.status),
      //       alert: true,
      //       skipTranslation: true,
      //       link: `/#/${data.resource}?highlight=${data.id}`,
      //       actionText: this.gotoText,
      //       action: goTo,
      //     });

      //     // if (!document.hasFocus()) {
      //     //   Push.create(data.title, {
      //     //     body: text,
      //     //     icon: "~/logo.svg",
      //     //     // requireInteraction: true,
      //     //     timeout: 10000,
      //     //     vibrate: [300, 200, 100],
      //     //     onClosed: function() {
      //     //       // goTo();
      //     //       window.focus();
      //     //     },
      //     //   });
      //     // }
      //   }
      // });
    },
  },
};
</script>
