<template>
  <div>
    <v-navigation-drawer
      app
      v-model="drawer"
      :right="getLanguage === 'ar'"
      style="z-index: 98"
      class="right-inner-shadow"
    >
      <!-- Logo Bar -->
      <v-app-bar color="primary" flat dark height="56">
        <img
          src="/name.svg"
          height="42"
          style="left: 6px; position: absolute"
        />
        <span
          class="secondary--text"
          style="left: 72px; top: 22px; font-size: 19pt; position: absolute"
          >{{ getSubname }}</span
        >
        <!-- <heartbeat style="position: absolute; right: 52px; margin-top: -30px" /> -->
        <typing-dots :active="react" color="secondary" style="position: absolute; right: 35px; margin-top: -30px" />
        <img
          src="/logo-full.svg"
          height="42"
          style="right: 8px; position: absolute"
        />
        <v-divider
          :style="`border-width: 1px;
            background: ${getBorderColor};
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;`"
        />
      </v-app-bar>

      <!-- Navigation Menu -->
      <navigation-menu
        style="
          position: absolute;
          width: 99%;
          height: calc(100vh - 88px);
          overflow-y: auto;
        "
      />
      <app-footer class="right-inner-shadow" />
    </v-navigation-drawer>

    <!-- Application Bar -->
    <app-bar v-model="drawer" base-color="secondary" />

    <v-main class="main-content d-flex align-start">
      <!-- View Content -->
      <view-container>
        <transition name="slide-x-reverse-transition">
          <router-view></router-view>
        </transition>
      </view-container>
      <price-reach-Bar />
    </v-main>

    <!-- Native Notification -->
    <native-notification />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NativeNotification from "@/common/components/NativeNotification";
import PriceReachBar from "@/common/components/PriceReachBar";
import AppBar from "@/common/components/AppBar";
import NavigationMenu from "@/common/components/NavigationMenu";
// import Heartbeat from "@/common/components/Heartbeat";
import TypingDots from "@/common/components/TypingDots";
import ViewContainer from "@/common/layouts/ViewContainer";
import AppFooter from "@/common/layouts/AppFooter";

export default {
  name: "AppLayout",
  components: {
    NativeNotification,
    AppBar,
    NavigationMenu,
    ViewContainer,
    AppFooter,
    PriceReachBar,
    // Heartbeat,
    TypingDots
  },
  data: () => ({
    drawer: true,
    react: false,
  }),
  mounted() {
    this.$sub("reactivity/*", () => {
      this.react = true;
      setTimeout(() => {
        this.react = false;
      }, 5000);
    });
  },
  beforeDestroy() {
    this.$unsubAll();
  },
  computed: {
    ...mapGetters("ui", ["getLanguage"]),
    ...mapGetters("stores", ["getStoreById"]),

    // getBorderColor() {
    //   return "#00000055";
    //   // return this.$vuetify.theme.themes.light.primary + "75";
    // },
    getSubname() {
      return this.$hasRole("DRIVER")
        ? "CAPTEN"
        : this.$hasRole("ADMIN") || this.$hasRole("SUPER-ADMIN")
        ? "ADMIN"
        : "";
    },
  },
  methods: {
    ...mapActions("stores", ["setSelectedStore", "clearSelectedStore"]),
  },
};
</script>

<style>
.main-content {
  overflow: hidden;
  background-color: #fff;
}

.v-data-table td,
.v-data-table th {
  font-size: 0.75rem;
  padding: 0 8px;
}

.col-12 {
  padding-top: 0;
}
.v-card__title {
  word-break: break-word;
}
</style>
