<template>
  <v-list v-if="user && user.uuid">
    <v-list-item link>
      <v-list-item-avatar @click="$router.push({ name: 'profile', params: { back: { name: $route.name }} })">
        <img :src="getAvatar" :alt="getUsername" height="50" />
      </v-list-item-avatar>
      <v-list-item-content @click="$router.push({ name: 'profile', params: { back: { name: $route.name }} })">
        <v-list-item-title v-if="user" class="title" v-text="getUsername">
        </v-list-item-title>
        <v-list-item-subtitle v-if="user && user.email" v-text="user.email" />
        <v-list-item-subtitle v-if="user && user.phone" v-text="user.phone" />
      </v-list-item-content>

      <v-list-item-action>
        <v-btn icon large v-if="user.uuid" @click="$router.push({ name: 'settings', params: { back: { name: $route.name }} })">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <!-- <v-menu v-if="user.uuid" left bottom style="z-index: 999;">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-menu-down</v-icon>
          </template>
          <v-list dense style="padding: 0;">
            <v-list-item
              @click="
                () =>
                  $router.push({
                    name: 'profile',
                    params: { back: { name: homePage } },
                  })
              "
            >
              <v-list-item-title>{{
                $t("app.route.profile")
              }}</v-list-item-title>
            </v-list-item>
            <v-divider />
            <v-list-item @click="tryLogout">
              <v-list-item-title>{{ $t("login.logout") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
      </v-list-item-action>
    </v-list-item>
    <v-divider />
  </v-list>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ProfileMenu",
  model: {
    prop: "user",
    event: "input",
  },
  props: {
    user: Object,
  },
  computed: {
    getAvatar() {
      return `${this.getApiBaseUrl}/files/avatar/${this.user.uuid}`;
    },
    getUsername() {
      return this.user.username || `${this.user.name}`;
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),

    async tryLogout() {
      const dialog = {
        title: "Logout",
        text: `Are you sure you want to logout?`,
        actions: [
          {
            text: "Yes",
            click: async () => {
              await this.logout();
              this.closeDialog();
              this.$router.replace({ name: "login" });
            },
          },
          {
            text: "No",
            click: () => this.closeDialog(),
            color: "primary",
          },
        ],
      };

      this.showDialog(dialog);
    },
  },
};
</script>
