import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAppBar,{staticStyle:{"z-index":"10"},attrs:{"app":"","color":"secondary","flat":"","height":"56"}},[_c(VAppBarNavIcon,{on:{"click":function($event){$event.stopPropagation();return _vm.$emit('toggle', !_vm.drawer)}}}),_c(VDivider,{attrs:{"vertical":""}}),(_vm.getStoreIdParam > 0 ? _vm.$allowed('store') : _vm.$allowed('stores'))?_c(VBtn,{attrs:{"icon":"","large":""},on:{"click":_vm.goToSearch}},[_c(VIcon,{attrs:{"large":_vm.isMd}},[_vm._v("mdi-magnify")])],1):_vm._e(),_c(VSpacer),(_vm.isCustomer)?_c('span',{staticStyle:{"line-height":"14px","font-weight":"600","font-size":"11pt"}},[_vm._v(" "+_vm._s(_vm.getAddressString)+" ")]):_vm._e(),(_vm.isCustomer)?_c(VBtn,{attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$router.push({
        name: 'addresses',
        params: { back: { name: _vm.homePage } },
      })}}},[_c(VIcon,{attrs:{"large":_vm.isMd}},[_vm._v("mdi-home-map-marker")])],1):_vm._e(),(_vm.isCustomer && !_vm.isMd)?_c(VDivider,{attrs:{"vertical":""}}):_vm._e(),(!_vm.isMd)?_c(VBtn,{attrs:{"icon":"","large":""}},[_c(VIcon,{on:{"click":function($event){return _vm.fullscreen()}}},[_vm._v("mdi-fullscreen")])],1):_vm._e(),(_vm.showCart)?_c(VBadge,{staticStyle:{"font-weight":"600"},attrs:{"content":_vm.getCartItemsQuantity,"right":"","top":"","offset-y":"22","offset-x":"22","color":"red","overlap":"","small":""}},[_c(VChip,{staticClass:"body-2 px-0",staticStyle:{"max-height":"46px"},attrs:{"label":"","to":_vm.getCartRoute,"color":"white","large":""}},[_c(VBtn,{attrs:{"icon":"","depressed":"","tile":"","min-width":"65"}},[_c(VIcon,{staticStyle:{"position":"absolute","top":"-21px"}},[_vm._v(" mdi-cart-variant ")]),_c('div',{staticClass:"text--primary",staticStyle:{"position":"absolute","top":"-2px","font-weight":"600","letter-spacing":"normal"}},[_c('sup',[_vm._v(" "+_vm._s(_vm.getCartCurrency || "")+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.getCartTotalPrice.toLocaleString())+" ")])])],1)],1)],1):_vm._e(),(!_vm.getCurrentUser.uuid)?_c(VBtn,{staticClass:"primary--text",attrs:{"link":"","to":"/auth/register","depressed":"","color":"secondary"}},[_vm._v(" Register ")]):_vm._e(),(!_vm.getCurrentUser.uuid)?_c(VBtn,{staticClass:"ml-2 primary--text",attrs:{"link":"","to":"/auth/login","depressed":"","color":"secondary"}},[_vm._v(" Login ")]):_vm._e(),_c(VDivider,{style:(("border-width: 1px;\n      background: " + _vm.getBorderColor + ";\n      position: absolute;\n      bottom: 0px;\n      left: 0;\n      right: 0;"))})],1)}
var staticRenderFns = []

export { render, staticRenderFns }