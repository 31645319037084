import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user && _vm.user.uuid)?_c(VList,[_c(VListItem,{attrs:{"link":""}},[_c(VListItemAvatar,{on:{"click":function($event){return _vm.$router.push({ name: 'profile', params: { back: { name: _vm.$route.name }} })}}},[_c('img',{attrs:{"src":_vm.getAvatar,"alt":_vm.getUsername,"height":"50"}})]),_c(VListItemContent,{on:{"click":function($event){return _vm.$router.push({ name: 'profile', params: { back: { name: _vm.$route.name }} })}}},[(_vm.user)?_c(VListItemTitle,{staticClass:"title",domProps:{"textContent":_vm._s(_vm.getUsername)}}):_vm._e(),(_vm.user && _vm.user.email)?_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.user.email)}}):_vm._e(),(_vm.user && _vm.user.phone)?_c(VListItemSubtitle,{domProps:{"textContent":_vm._s(_vm.user.phone)}}):_vm._e()],1),_c(VListItemAction,[(_vm.user.uuid)?_c(VBtn,{attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.$router.push({ name: 'settings', params: { back: { name: _vm.$route.name }} })}}},[_c(VIcon,[_vm._v("mdi-cog")])],1):_vm._e()],1)],1),_c(VDivider)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }